import React, { useContext } from 'react'
import PageContext from '@context'
import SbEditable from 'storyblok-react'
import { Link as GatsbyLink } from 'gatsby'
import {
  Box,
  Typography,
  List,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import ACC_THEME from '@themes'
import addPrecedingAndRemoveTrailingSlashes from '@helpers/add-preceding-and-remove-trailing-slashes'
import { addPrecedingSlash, addTrailingSlash } from '@helpers'
import useParams from '@hooks/use-params'
import multilinkToUrl from '@helpers/multilink-to-url'
import formatLocalizedLink from '@helpers/format-localized-link'

const useStyles = makeStyles((theme) => ({
  heading: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      fontSize: '24px',
      fontWeight: theme.typography.fontWeightLight,
      marginBottom: '24px',
    },
    '& > a > div > span': {
      fontSize: 24,
      fontWeight: theme.typography.fontWeightLight,
      marginBottom: '1rem',
      color: ACC_THEME.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
        textDecorationThickness: '1px',
        inlineSize: 'fit-content',
      },
    },
  },
  itemsContainer: ({ numberOfListItems }) => ({
    display: 'grid',
    gridColumnGap: '32px',
    gridRowGap: '48px',
    gridTemplateColumns: `repeat(${numberOfListItems}, minmax(0, 1fr))`,
    [theme.breakpoints.up('lg')]: {
      gridColumnGap: '48px',
      gridRowGap: '56px',
    },
  }),
  menuHeadline: {
    marginBottom: '1rem',
    padding: '0px 0px',
    '& span': {
      color: ACC_THEME.palette.common.white,
      fontSize: '0.95rem',
    },
  },

  link: {
    textDecoration: 'none',
  },
}))

const HeadingLinkWrapper = (props) => {
  const {
    headingLink: { linktype, url, story },
    heading,
  } = props
  const isInEditor = useContext(PageContext).isInEditor
  const classes = useStyles(props)
  const query = useParams()
  const editorClick = () => {
    window.location.href = href
  }
  const isStory = linktype === 'story'
  const sb_url = isStory && story && story.url
  // const storyName = isStory && story && story.name
  const storyUrl = isStory ? sb_url : null
  const href = isStory
    ? isInEditor
      ? `/editor/?path=${addTrailingSlash(sb_url)}${query.replace('?', '&')}`
      : addPrecedingAndRemoveTrailingSlashes(`${storyUrl}${query}`)
    : url
    ? addPrecedingSlash(url)
    : ''

  return isStory ? (
    <GatsbyLink
      to={formatLocalizedLink(href)}
      activeClassName="active"
      partiallyActive={false}
      className={classes.link}
      onClick={isInEditor ? editorClick : undefined}
    >
      <>
        <ListItemText primary={heading} />
      </>
    </GatsbyLink>
  ) : (
    <a className={classes.link} href={href}>
      <ListItemText primary={heading} />
    </a>
  )
}

const TopNavProductsMenuProductsMenu = (props) => {
  const isInEditor = useContext(PageContext).isInEditor
  const { headingLink, heading, items } = props.blok
  const classes = useStyles({ ...props, numberOfListItems: items?.length })
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const headingUrl = !!headingLink && multilinkToUrl(headingLink, isInEditor)

  return (
    <SbEditable key={props.blok._uid} content={props.blok}>
      {!handHeldDevice ? (
        <Box component="span" className={classes.heading}>
          {heading && headingUrl ? (
            <HeadingLinkWrapper headingLink={headingLink} heading={heading}>
              <ListItemText primary={heading} />
            </HeadingLinkWrapper>
          ) : (
            heading && <Typography>{heading}</Typography>
          )}
          <Box className={classes.itemsContainer}>{renderBloks(items)}</Box>
        </Box>
      ) : (
        <List component="div" disablePadding>
          {heading && (
            <ListItemText primary={heading} className={classes.menuHeadline} />
          )}
          {renderBloks(items)}
        </List>
      )}
    </SbEditable>
  )
}

export default TopNavProductsMenuProductsMenu
